import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import {Carousel} from 'react-bootstrap'

const PilatesPage = ({ data }) => (
  <Layout>
    <SEO title={data.strapiPilates.meta_titel + " | Pilates"} description={data.strapiPilates.meta_omschrijving} lang="nl" />
    <div className="container">
      <div className="row no-gutters cta-wiezijnwe mb-5 ">
        <div className="col-lg-6 d-flex flex-column justify-content-center bg-wervel">
          <div className="cta-wiezijnwe-tekst">
            <h1>{data.strapiPilates.titel}</h1>
            <ReactMarkdown source={data.strapiPilates.tekst} />
          </div>
        </div>
        <div className="col-lg-6 cta-wiezijnwe-afb">
          <Carousel>
            {data.strapiPilates.afbeeldingen.sort((a, b) => (a.name > b.name) ? 1 : -1).map(document =>(
              <Carousel.Item key={document.id}>
                {document.width > document.height &&
                  <img
                    className="d-block w-100"
                    src={document.url}
                    alt={document.alternativeText}
                  />
                }
                {document.width < document.height &&
                  <img
                    className="d-block carouselItemPortrait"
                    src={document.url}
                    alt={document.alternativeText}
                  />
                }
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <>
        {data.strapiPilates.tekst2 &&
          <div className="row">
            <div className="col-lg-9 mb-5 team-opleidingen">
              <ReactMarkdown source={data.strapiPilates.tekst2} />
            </div>
          </div>
        }
      </>
    </div>
  </Layout>
)

export default PilatesPage

export const query = graphql`
  query PilatesQuery {
    strapiPilates {
      titel
      tekst
      tekst2
      meta_titel
      meta_omschrijving
      afbeeldingen {
        alternativeText
        id
        width
        height
        name
        url
      }
    }
  }
`